<script>
import BoCard from "@/components/backend/BoCard.vue"
import BoSearch from "@/components/backend/BoSearch.vue"
import BoPageTitle from "@/components/backend/BoPageTitle.vue"

import { mapState } from 'vuex'
import Config from './Config'
import Gen from '@/libs/Gen.js'
import $ from 'jquery'
import _ from 'lodash'

export default {
  components: {
    BoCard, BoSearch, BoPageTitle
  },
  data() {
    return {
      maskCurrency:{
        decimal: ',', thousands: '.', 
        prefix: '', suffix: '', 
        precision:0, masked: false
      },
      tableTransitionProps: {
        name: 'table-transition'
      },
      statusOptions: [
        { text: 'Active', value: 'Y' },
        { text: 'Inactive', value: 'N' },
      ],
      statusFilters: [
        { label: 'All Status', value: '' },
        { label: 'Active', value: 'Y' },
        { label: 'Inactive', value: 'N' },
      ],
      customerTypeFilter: [
        { label: 'All Customer Types', value: '' },
        { label: 'Residential', value: 'residencial' },
        { label: 'Company', value: 'company' },
      ],
      customerTypeSelect: [
        { label: 'Residential', value: 'residencial' },
        { label: 'Company', value: 'company' },
      ],
      prospectSourceFilter: [
        { label: 'All Sources', value: '' },
        { label: 'Ads', value: 'ads' },
        { label: 'Google', value: 'google' },
        { label: 'Marketplace', value: 'marketplace' },
        { label: 'Organic', value: 'organic' },
        { label: 'Reference', value: 'reference' },
        { label: 'Social Media', value: 'social-media' },
        { label: 'Website', value: 'website' },
      ],
      prospectSourceSelect: [
        { label: 'Ads', value: 'ads' },
        { label: 'Google', value: 'google' },
        { label: 'Marketplace', value: 'marketplace' },
        { label: 'Organic', value: 'organic' },
        { label: 'Reference', value: 'reference' },
        { label: 'Social Media', value: 'social-media' },
        { label: 'Website', value: 'website' },
      ],
      contactMediaSelect: [
        { label: 'Call', value: 'call' },
        { label: 'Dirrect Message', value: 'direct-message' },
        { label: 'On The Spot', value: 'ots' },
        { label: 'Website', value: 'website' },
        { label: 'WhatsApp', value: 'wa' },
      ],
      contactMediaFilter: [
        { label: 'All Contact Media', value: '' },
        { label: 'Call', value: 'call' },
        { label: 'Dirrect Message', value: 'direct-message' },
        { label: 'On The Spot', value: 'ots' },
        { label: 'Website', value: 'website' },
        { label: 'WhatsApp', value: 'wa' },
      ],
      statusProspectOptions: [
        { text: 'Follow Up', value: 'FU' },
        { text: 'Survey', value: 'S' },
        { text: 'Closing', value: 'C' },
        { text: 'Pending', value: 'P' },
        { text: 'Reject', value: 'R' },
      ],
      filter: {},
      row: {},
      input:{},
      mrValidation: {},
      data: {data: false},
      duration:35000,
      pattern:{
        name:/^[a-zA-Z '.,]+$/,
        username:/^[a-zA-Z0-9_.]+$/,
        phone:/^(?=[1-9])[0-9]+$/,
      },
      customerBadge: {
        R: {
          color: 'warning',
          word: 'Running'
        },
        S: {
          color: 'info',
          word: 'Scheduled'
        },
        F: {
          color: 'success',
          word: 'Finished'
        }
      },
      prospectBadge: {
        FU: {
          color: 'info',
          word: 'Follow Up'
        },
        S: {
          color: 'primary',
          word: 'Survey',
        },
        C: {
          color: 'success',
          word: 'Closing'
        },
        R: {
          color: 'danger',
          word: 'Reject'
        },
        P: {
          color: 'warning',
          word: 'Pending'
        }
      },
      surveyBadge: {
        schedule: {
          color: 'info',
          word: 'Schedule'
        },
        done: {
          color: 'success',
          word: 'Finished',
        },
        cancel: {
          color: 'danger',
          word: 'Cancelled'
        },
        R: {
          color: 'warning',
          word: 'Running'
        },
      }
    }
  },
  computed: {
    isMobile() { return window.innerWidth < 768 },
    isTablet() { return window.innerWidth >= 768 && window.innerWidth < 992 },
    isDesktop() { return !this.isMobile && !this.isTablet },
    breadcrumbs() { return this.$route.meta.breadcrumbs },
    objName() { return this.$route.meta.objName },
    hasCta() { return this.$route.meta.hasCta },
    addCta() { return this.$route.meta.addCta },
    routeName() { return this.$route.name },
    

    ...mapState({
      production: state => state.production,
      apiUrl: state => state.apiUrl,
      baseUrl: state => state.baseUrl,
      foBaseUrl: state => state.foBaseUrl,
      webTitle: state => state.webTitle,
      userToken: state => state.userToken,
      user: state => state.user,
    }),
    loadingOverlay:{
      get(){ return this.$root.var.loadingOverlay },
      set(v){ return this.$root.var.loadingOverlay = v },
    },
    Config(){ return Config },

    pageSlug(){ return this.$route.params.pageSlug },
    pageId(){ return this.$route.params.pageId },
    isAdd(){ return this.pageSlug == "add" },
    isList(){ return !this.pageSlug },
    isSorting(){return this.$route.query.sorting&&this.isList},
    isDelete(){return this.$route.query.delete&&this.isList},

    dataList(){return this.data.data },

    pageTitle(){return this.$route.meta?.pageTitle||""},
    pageTitleForm(){return (this.pageSlug == 'add' ? 'Add' : 'Edit')+' '+this.pageTitle},
    modulePage(){return this.$route.meta.menu},

    pageNo: {
      // getter
      get:function() {
        return this.filter.page||this.$route.query.page||1
      },
      // setter
      set:function(newValue) {
        this.$set(this.filter, "shown", this.perPage)
        this.$set(this.filter, "page", newValue)
        this.$router.push({
          name:this.modulePage,
          query:_.clone(this.filter)
        })
      }
    },

    perPage:{
      // getter
      get:function() {
        return this.$route.query.shown||this.filter.shown||Config.dataShown
      },
      // setter
      set:function(newValue) {
        this.pageNo=1
        this.$router.push({
          name:this.modulePage,
          query:{shown:newValue}
        })
      }
    },

    apiParams(){ return _.assign(_.clone(this.filter), _.clone(this.apiQuery||{})) },
    apiQuery(){return this.$route.query||{}},

    notFound(){
      if (this.data.data === false) return false
      if (this.data.data.length > 0) return false
      if (this.$route.query.search) {
        return "Search with keyword '" + this.$route.query.search + "' not found"
      }
      return this.pageTitle + " not found"
    },
    filterNotEmpty(){      
      return $.grep(Object.keys(this.filter)||[], attr=>{
        return this.filter[attr] && attr!='page'
      }).length >= 1
    },
    passToSubComp(){
      return {
        isAdd:this.isAdd,
        mrValidation:this.mrValidation,
        Config:this.Config,
      }
    }
  },
  mounted() {
    this.filter = _.clone(this.$route?.query)
  },
  methods: {
    getPercentage(value, total){
      return (value / total * 100).toFixed(2)
    },
    contactMedia(val) {
      const data = this.contactMediaFilter.find(el => el.value === val)
      return data || {value : "-", label: "-"}
    },
    prospectSrc(val) {
      const data = this.prospectSourceFilter.find(el => el.value === val)
      return data || {value : "-", label: "-"}
    },
    getRelativeDayInWeek(d,dy) {
      d = new Date(d)
      var day = d.getDay(),
      diff = d.getDate() - day + dy
      return new Date(d.setDate(diff))
    },
    implode(a, b){ 
      if(typeof b == 'string') b = JSON.parse(b)
      return b.join(a)
    },
    download(path, optParams = {}){
      let reqUrl = process.env.VUE_APP_API_URL + "/api/bo/get/"+this.modulePage

      let params = Object.assign({export:1, token: Gen.getCookie("bo_auth")||"", download: path}, optParams, this.$route.query)
      params = new URLSearchParams(params).toString()

      reqUrl += '?'+params

      window.open(reqUrl, '_blank')
    },
    isType(data){
      return typeof data
    },
    apiGet(params = {}){
      if(this.pageSlug || this.$route.name=='Dashboard') this.loadingOverlay = true
      this.data.data = false
      let slugs = (this.pageSlug?"/"+this.pageSlug:"")+(this.pageId?"/"+this.pageId:"")
      if(params.slugs) slugs = params.slugs
      Gen.apiRest(
        "/get/"+this.modulePage+
        slugs, 
        {
          params: Object.assign({}, this.apiParams, params.query||{})
        }
      ).then(res=>{
        this.loadingOverlay = false

        _.forEach(res.data, (v,k)=>{
          this.$set(this, k, v)
        })

        if(params.callback) params.callback(res, this.modulePage, this.$router)
        
        if(!this.isList){
          this.setFocusForm()
        }
        
      }).catch(()=>{
        this.loadingOverlay = false
      })
    },
    setFocusForm(){
      if(this.$refs.VForm.$el){
        let el = $(this.$refs.VForm.$el)
        if(el.find(':input:not([type=hidden])').length){
          el.find(':input:not([type=hidden]):first').focus()
        }else if(el.find('textarea').length){
          el.find('textarea:first').focus()
        }
      }
    },
    setTitle(){
      $("title").html(this.pageTitle+' - '+this.webTitle)
    },
    doFilter(urlOnly=false){
      if(this.exportType && this.filter){
        let f = Object.values(this.filter).filter(attr=>attr)
        if(f.length>=1){ 
          this.exportType = 'filtered'
        }else{
          this.exportType = 'all'
        }
      }
      let url = {
        name:this.$route.name,
        params: (this.$route.params||{}),
        query:Object.assign({}, this.$route.query||{},_.clone(this.filter), {page:1})
      }
      
      if(urlOnly) return url
      this.pageNo=1
      this.$router.push(url).catch(()=>{})
    },
    doSortBy(){
      let url = this.doFilter('urlOnly')
      url.query = Object.assign(url.query, {sortByField:this.sortBy, sortByType:this.sortDesc?'desc':'asc'})
      this.$router.push(url).catch(()=>{})
    },
    doReset(){
      const filterKeys = Object.keys(this.filter)
      const querys = _.assign(_.omit(this.$route.query||{}, filterKeys))

      this.filter={}
      this.$set(this, 'active_filter', '')
      this.$router.push({name:this.$route.name,query:querys})
    },
    doChangeStatus(k,v, params={}){
      let id = v[this.idKey]
      let changeStatus = v[this.statusKey] == "Y" ? "N" : "Y"
      this.$swal({
        title: `Change status to ${changeStatus=='N' ? 'Inactive' : 'Active'}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText:  `Yes, change to ${changeStatus=='N'? 'Inactive' : 'Active'}!`,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          this.loadingOverlay=true

          let slugs=""
          if(params.slugs) slugs = params.slugs

          Gen.apiRest(
            "/do/"+this.modulePage+slugs,
            {data:{type:'status',id:id,status:changeStatus}}, 
            "POST"
          )
            .then(()=>{
              this.loadingOverlay = false 
              v[this.statusKey] = changeStatus

              this.$swal({
                title: `Status changed to ${changeStatus=='Y'? 'Active' : 'Inactive'}.`,
                icon: 'success',
              })
              this.apiGet()
            })
            .catch(err=>{ 
              this.loadingOverlay = false 
              if(err){
                  err.statusType = err.status
                  err.status = "error"
                  err.message = err.response.data.message
                  err.title = err.response.data.title
                  err.messageError = err.message
              }
              this.doSetAlertForm(err)
            })
        }
      })
    },
    doDelete(k,v, params={}){

      if(v?.related == 'Y'){
        return this.$swal({
          icon: 'error',
          title: "You Can't Delete This Level",
          text: 'This Level already has a list of users'
        })
      }

      let id = v[this.idKey]
      let obj = params.obj||this.$route.meta.objName
      this.$swal({
        title: 'Delete this ' + obj + '?' || 'Data' + '?',
        text: "You won't be able to restore this data!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.loadingOverlay = true
          this.openForm = false

          let slugs=""
          if(params.slugs) slugs = params.slugs

          Gen.apiRest("/do/"+this.modulePage+slugs,{data:{type:'delete',id:id}}, "POST")
            .then((resp = {})=>{
              this.apiGet(params)
              // let obj = this.$route.meta.objName              
              this.$swal({
                title: resp.data.message || 'Deleted Successfully!',
                icon: 'success',
              })
            })
            .catch(err=>{ 
              this.loadingOverlay = false 
              if(err){
                  err.statusType = err.status
                  err.status = "error"
                  err.title = err.response.data.title
                  err.message = err.response.data.message
                  err.messageError = err.message
              }
              this.doSetAlertForm(err)
            })
        }
      })
    },
    reparse(js){
      return $.parseJSON(JSON.stringify(js))
    },
    doSubmit(action, input, callback=false, method="POST", IDFORM='VForm', refs=false){      
      const refsObj = refs||this.$refs
      refsObj[IDFORM].validate().then(success => {
        if (!success) return
        
        if(this.loadingOverlay) return
        this.loadingOverlay = true 

        var data = $.parseJSON(JSON.stringify(input))
        var btnSubmit = $(".btn-loading")
        var prevHtml = btnSubmit.html()

        if($(".infoHTML").length){
          $(".infoHTML").removeClass('alert alert-danger alert-warning')
          $(".infoHTML").html('')
        }

        btnSubmit.html()
        Gen.apiRest(action, {data: $.extend({params:this.apiParams}, data)}, method).then(res=>{
            this.loadingOverlay = false
            let resp = res.data

            btnSubmit.html(prevHtml)

            resp.statusType = 200
            this.doSetAlertForm(resp)
            
            if(callback){
              callback('success', resp)
            }

            setTimeout(()=>{ this.message="" },15000)
        }).catch(err=>{
            this.loadingOverlay = false
            btnSubmit.html(prevHtml)
            
            if(err){
                err.statusType = err.status
                err.status = "error"
                err.message = err.response?.data?.message
                err.messageError = err.message
            }

            this.doSetAlertForm(err)
            if(this.failReset)
                this.$nextTick(() => {
                    refsObj[IDFORM].reset()
                    this.message=""
                })
                
            if(callback){
              callback('error', err)
            }
        })
      })
    },
    doSubmitCRUD(IDForm='VForm', refs=false){      
      return this.doSubmit(
          "/do/"+this.modulePage, 
          _.assign({type:this.isAdd?'add':'update'}, {id: (this.pageId||this.pageSlug)}, _.clone(this.row)), 
          (type, resp)=>{
            if(type=='success'){
              this.$router.push({name:this.$route.name})
            }else{
              if(resp.response.status==422) {
                if((_.values(resp.response.data)[0][0]||"").indexOf('validation')>-1){
                  var msg = ""
                  $.each(Object.keys(resp.response.data),(i,v)=>{
                    msg = msg+" <br/>"+v+" : "+_.values(resp.response.data[v])                    
                  })
                  return this.$swal("Blocked With Validation!", msg)
                }else{
                  return this.$swal("Sorry, There is something wrong!", _.values(resp.response.data)[0][0])
                }
              }else if(resp.response.status==400){
                return this.$swal({
                  title: resp.response.data.message,
                  icon: 'error',
                  confirmButtonText: 'Ok'
                })
              }
            }
          },
          'POST', IDForm, refs
      )
    },
    eType(e){return $.type(e)},
    doDownloadTemplate(name){
      let reqUrl = this.apiUrl + "/template"
      reqUrl += "/"+name
      window.open(reqUrl, '_blank')
    },
    doExport(p=false){
      let reqUrl = this.apiUrl + "/api/get/"+this.modulePage
      if(this.modulPage=='ProjectDataRawListing'){
        reqUrl = "/"+this.projectSlug+(this.pageSlug?"/"+this.pageSlug:"")
      }
      reqUrl += (this.pageSlug?"/"+this.pageSlug:"")
      reqUrl += (this.pageId?"/"+this.pageId:"")
      
      let params = Object.assign({export:1, token: Gen.getCookie("u_auth")||""}, this.apiParams)
      params = new URLSearchParams(params).toString()
      
      reqUrl += '?'+params
      if(p) reqUrl += p

      window.open(reqUrl, '_blank')
    },
    doSetAlertForm(resp){
      if(!resp.message) return
      var panel = $(".infoHTML")
      if(resp.alert=="normal"){
        if(["success","warning"].indexOf(resp.status)>-1){
          panel.attr("class","infoHTML alert alert-"+resp.status)
        }else{
          panel.attr("class","infoHTML alert alert-danger")
        }

        panel.html("<div class='alert-body'>"+resp.message+"</div>")

        setTimeout(()=>{
          panel.html("")
          panel.attr("class","infoHTML")
        }, this.duration)
      }else {
        if($.inArray(resp.status,["success","warning"])!=-1){          
          return this.$swal({
            // title: 'Information',
            title: resp.message,
            icon: resp.status,
            confirmButtonText: 'Ok'
          })
        }else{
          // if(this.$route.name == 'BoUserLevel'){
          //   return this.$swal({
          //     title: resp.title || 'Sorry, There is something wrong!',
          //     text: resp.message,
          //     icon: 'error',
          //     confirmButtonText: 'Ok'
          //   })
          // }
          return this.$swal({
            title: resp.title || 'Sorry, There is something wrong!',
            text: resp.message,
            icon: 'error',
            confirmButtonText: 'Ok'
          })
        }
      }
    },

    errorMessage(err){
      this.loadingOverlay = false
      let res = err.response
      if(res.status==422) return this.$swal("Sorry, There is something wrong!", _.values(res.data)[0][0])
      this.$swal("Sorry, There is something wrong!", res.data.message, "warning")
    },
    doLogout:function(){
      this.$store.commit("logoutUserToken")
      if(!this.userToken){
        this.$router.push({name:"Login"})
      }
    },
    uploader(filename, def="/assets/images/user-placeholder.svg") {
      if(!filename){
        return def
      }
      return this.apiUrl + '/uploader/' + filename
    },
    configValue(data, value){
      let dt = $.grep((this.Config.mr[data]||[]), attr=>{ return attr.value==value })
      return dt.length?dt[0].label:""
    },
    moduleRole(pRole){
      if(this.$store.getters['canManageAll']) return true
      let role = typeof role !== Array ? [pRole] : pRole

      const moduleRole = _.filter(this.menuRole().module||[], v=>{
        return role.indexOf(v)>-1
      })

      return !!moduleRole.length
    },
    menuRole(){
      return _.find(this.user.menuRole||[], v=>{
        return v.menu == this.$route.meta?.menu
      })||{}
    },

    deleteItem(index, dataSource) {
      this.$swal({
        icon: 'warning',
        title: 'Are you sure?',
        text: 'You cannot recover deleted data!',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it',
        cancelButtonText: 'No, cancel'
      }).then(result => {
        if (result.value) {
          dataSource.splice(index, 1)
          this.$swal({
            icon: 'success',
            title: 'Data deleted succesfully'
          })
        }
      })
    },
    changeStatus(index, dataSource) {
      let stats = dataSource[index].status == 'Y' ? 'Inactive' : 'Active'
      this.$swal({
        icon: 'warning',
        title: 'Change status to '+stats+'?',
        showCancelButton: true,
        confirmButtonText: 'Yes, change it',
        cancelButtonText: 'No, cancel'
      }).then(result => {
        if (result.value) {
          dataSource[index].status = dataSource[index].status == 'Y' ? 'N' : 'Y'
          this.$swal({
            icon: 'success',
            title: 'Status changed to '+stats
          })
        }
      })
    },
    cancelAddUpdate(method) {
      this.$swal({
        icon: 'warning',
        title: 'Are you sure?',
        text: 'Any changes you made will be lost!',
        showCancelButton: true,
        confirmButtonText: 'Yes, go back',
        cancelButtonText: 'No, cancel'
      }).then(result => {
        if (result.value) {
          method ? method() : this.$router.push({ name: this.$route.name })
        }
      })
    },
    confirmChanges(type, obj) {
      const objName = (obj ? obj : 'Data') + ' ' + (type == 'update' ? 'Updated' : 'Added')
      this.$swal({
        icon: 'success',
        title: objName+' Successfully.'
      })
    },
    getQuartal(month) {
      if(month == 0 || month == 1 || month == 2){
        return {
          sQ : 0,
          eQ : 2
        }
      }
      else if(month == 3 || month == 4 || month == 5){
        return {
          sQ : 3,
          eQ : 5
        }
      }
      else if(month == 6 || month == 7 || month == 8){
        return {
          sQ : 6,
          eQ : 8
        }
      }
      else{
        return {
          sQ : 9,
          eQ : 11
        }
      }
    },

    submitHero(IDForm = 'VFormHero'){
      this.doSubmit(
        "/do/" + this.modulePage,
        _.assign({
          type: 'updateHero'
        }, this.heroImage),
        (type, resp) => {
          if (type == 'success') {
            this.loadingOverlay = true  
            this.apiGet()
            this.heroEditorOpen = false
          }else{
            if(resp.response.status==422) {
              if((_.values(resp.response.data)[0][0]||"").indexOf('validation')>-1){
                var msg = ""
                $.each(Object.keys(resp.response.data), (i, v) => {
                  msg = msg + " <br/>" + v + " : " + _.values(resp.response.data[v])
                })
                return global.Swal.fire("Blocked With Validation!", msg)
              } else {
                return global.Swal.fire("Sorry, There is something wrong!", _.values(resp.response.data)[0][0])
              }
            } else if (resp.response.status == 400) {
              return global.Swal.fire({
                title: resp.response.data.message,
                icon: 'error',
                confirmButtonText: 'Ok'
              })
            }
          }
        },
        'POST', IDForm
      )
    },
    
    submitSEO(IDForm = 'VFormSEO'){
      this.doSubmit(
        "/do/" + this.modulePage,
        _.assign({
          type: 'updateSeo'
        }, this.seoPageSetting),
        (type, resp) => {
          if (type == 'success') {
            this.loadingOverlay = true  
            this.apiGet()
          }else{
            if(resp.response.status==422) {
              if((_.values(resp.response.data)[0][0]||"").indexOf('validation')>-1){
                var msg = ""
                $.each(Object.keys(resp.response.data), (i, v) => {
                  msg = msg + " <br/>" + v + " : " + _.values(resp.response.data[v])
                })
                return global.Swal.fire("Blocked With Validation!", msg)
              } else {
                return global.Swal.fire("Sorry, There is something wrong!", _.values(resp.response.data)[0][0])
              }
            } else if (resp.response.status == 400) {
              return global.Swal.fire({
                title: resp.response.data.message,
                icon: 'error',
                confirmButtonText: 'Ok'
              })
            }
          }
        },
        'POST', IDForm
      )
    },
    nik(e) {
      return String(e).substring(0, 16)
    },
    rt_rw(e){
      return String(e).substring(0, 3)
    },
    telp(e){
      return String(e).replace(/[+,\-,\s]/g, '').substring(0, 16)
    },
    formatRupiah(value) {
      return new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0}).format(value)
    },
    formatThousand(value) {
      let data = new Intl.NumberFormat('id-ID').format(value)
      return Number(data)
    },
    lengthArr(arr){
      return arr?.length > 0
    },
    length(arr){
      return arr?.length
    }
  },
}
</script>