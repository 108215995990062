const bo_routes = [
  {
    path: '',
    redirect: { name: 'BoLogin' },
  },
  {
    path: 'login',
    name: 'BoLogin',
    component: () => import('@/views/backend/BoLogin.vue'),
    meta: {
      redirectIfLoggedIn: true,
      layout: 'empty',
    }
  },
  {
    path: 'dashboard/:pageSlug?',
    name: 'BoDashboard',
    component: () => import('@/views/backend/BoDashboard.vue'),
    meta: {
      menu: 'BoDashboard',
      pageTitle: 'Dashboard',
      objName: 'Dashboard',
    },
  },
  {
    path: 'assignment/:pageSlug?/:pageId?',
    name: 'BoAssignment',
    component: () => import('@/views/backend/BoAssignment/List.vue'),
    meta: {
      pageTitle: 'Assignment',
      menu: 'BoAssignment',
      hasCta: 'true',
      objName: 'Assignment'
    }
  },
  {
    path: 'notification/:pageSlug?',
    name: 'BoNotification',
    component: () => import('@/views/backend/BoNotification.vue'),
    meta: {
      menu: 'BoNotification',
      pageTitle: 'Notification',
      objName: 'Notification',
    },
  },
  {
    path: 'account-setting',
    name: 'BoAccountSetting',
    component: () => import('@/views/backend/BoAccountSetting.vue'),
    meta: {
      menu: 'BoAccount',
      pageTitle: 'Account Setting',
      objName: 'Account',      
    },
  },
  {
    path: 'user/:pageSlug?',
    name: 'Users',
    component: () => import('@/views/backend/BoUser/List.vue'),
    meta: {
      menu: "Users",
      pageTitle: 'Users',
      hasCta: 'true',
      objName: 'User'
    },
  },
  {
    path: 'user-level/:pageSlug?',
    name: 'BoUserLevel',
    component: () => import('@/views/backend/BoUserLevel/List.vue'),
    meta: {
      menu: 'UserLevel',
      pageTitle: 'User Level',
      hasCta: 'true',
      objName: 'User Level'
    },
  },
  {
    path: 'settings',
    name: 'BoWebSettings',
    component: () => import('@/views/backend/BoWebSettings/BoWebSettings.vue'),
    meta: {
      pageTitle: 'App Settings',
      menu: 'BoWebSetting',
      objName: 'App Settings'
    }
  },
  {
    path: 'surveyor/:pageSlug?/:pageId?',
    name: 'BoSurveyor',
    component: () => import('@/views/backend/BoSurveyor/List.vue'),
    meta: {
      pageTitle: 'Surveyor',
      menu: 'BoSurveyor',
      hasCta: 'true',
      objName: 'Surveyor'
    }
  },
  {
    path: 'prospect-category/:pageSlug?/:pageId?',
    name: 'BoProspectCategory',
    component: () => import('@/views/backend/BoProspectCategory/List.vue'),
    meta: {
      pageTitle: 'Prospect Category',
      menu: 'BoProspectCategory',
      hasCta: 'true',
      objName: 'Prospect Category'
    }
  },
  {
    path: 'position/:pageSlug?/:pageId?',
    name: 'BoPosition',
    component: () => import('@/views/backend/BoPosition/List.vue'),
    meta: {
      pageTitle: 'Position',
      menu: 'BoPosition',
      hasCta: 'true',
      objName: 'Position'
    }
  },
  {
    path: 'partner/:pageSlug?/:pageId?',
    name: 'BoPartner',
    component: () => import('@/views/backend/BoPartner/List.vue'),
    meta: {
      pageTitle: 'Partner',
      menu: 'BoPartner',
      hasCta: 'true',
      objName: 'Partner'
    }
  },
  {
    path: 'target/:pageSlug?/:pageId?',
    name: 'BoTarget',
    component: () => import('@/views/backend/BoTarget/List.vue'),
    meta: {
      pageTitle: 'Target',
      menu: 'BoTarget',
      hasCta: 'true',
      objName: 'Target'
    }
  },
  {
    path: 'prospect/:pageSlug?/:pageId?',
    name: 'BoProspect',
    component: () => import('@/views/backend/BoProspect/List.vue'),
    meta: {
      pageTitle: 'Prospect',
      menu: 'BoProspect',
      objName: 'Prospect',
      ctaList: 'true'
    }
  },
  {
    path: 'customer/:pageSlug?/:pageId?',
    name: 'BoCustomer',
    component: () => import('@/views/backend/BoCustomer/List.vue'),
    meta: {
      pageTitle: 'Customer',
      menu: 'BoCustomer',
      ctaList: 'true',
      objName: 'Customer'
    }
  },
  {
    path: 'after-sales/:pageSlug?/:pageId?',
    name: 'BoAfterSalesService',
    component: () => import('@/views/backend/BoAfterSales/List.vue'),
    meta: {
      pageTitle: 'After Sales',
      menu: 'BoAfterSalesService',
      ctaList: 'true',
      objName: 'After Sales'
    }
  },
  {
    path: 'in-out-cash',
    name: 'BoInOutCash',
    component: () => import('@/views/backend/BoInOutCash.vue'),
    meta: {
      pageTitle: 'In Out Cash',
      menu: 'BoInOutCash',      
      objName: 'In Out Cash',
    }
  },
  {
    path: 'expense/:pageSlug?/:pageId?',
    name: 'BoExpense',
    component: () => import('@/views/backend/BoExpense/List.vue'),
    meta: {
      pageTitle: 'Expense',
      menu: 'BoExpense',
      ctaList: 'true',
      objName: 'Expense'
    }
  },
  {
    path: 'survey/:pageSlug?/:pageId?',
    name: 'BoSurveySchedule',
    component: () => import('@/views/backend/BoSurveySchedule/List.vue'),
    meta: {
      pageTitle: 'Survey Schedule',
      menu: 'BoSurveySchedule',
      ctaList: 'true',
      objName: 'Survey Schedule'
    }
  },
  {
    path: '/report-statistics/:pageSlug?',
    name: 'BoReportStatistics',
    component: () => import('@/views/backend/BoReport/BoReportStatistics.vue'),
    meta: {
      pageTitle: 'Report Statistics',
      menu: "BoReportStatistics",
      objName: "Report Statistics"
    }
  }, 
  {
    path: '/financial-report/:pageSlug?',
    name: 'BoReportFinancial',
    component: () => import('@/views/backend/BoReport/BoReportFinancial.vue'),
    meta: {
      pageTitle: 'Report Financial',
      menu: "BoReportFinancial",
      objName: "Report Financial"
    }
  },
  {
    path: 'audittrail/:pageSlug?/:pageId?',
    name: 'BoAuditTrail',
    component: () => import('@/views/backend/BoAuditTrail/AuditTrail.vue'),
    meta: {
      pageTitle: 'Audit Trail',
      menu: 'BoAuditTrail',
      objName: 'Audit Trail'
    }
  },
]
export default bo_routes